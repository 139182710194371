.DropZone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999999;
    width: 100%;
    height: 100%;
    background: rgba(28,36,59,0.93);
    transition: visibility 175ms, opacity 175ms;
    padding: 20px;
    box-sizing:border-box;
    visibility: hidden;
    opacity: 0;
}

/* .DropZone::after{
    content: "";
    display: block;
    width: 360px;
    height: 360px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    box-sizing:border-box;
    z-index: 0;
    border: 6px dashed rgba(112,119, 163, 0.50);
    border-radius: 30px;
    align-self: center;
} */

.DropZone__icon{
    height: 90px;
    margin-bottom: 20px;
}

.DropZone__title{
    font-size: 22px;
    font-weight: bold;
    color: #ffffff;
}

.DropZone--is-active{
    visibility: visible;
    opacity: 1;
}