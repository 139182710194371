.ReceiveModal {
    width: 30vw;
    padding: 20px;
    box-sizing: border-box;
    background: #282828;
    box-shadow: inset 0px 0px 6px #ffffff45;
    border-radius: 10px!important;
    opacity: 1;
   
}

.ReceiveModal__title {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    color: white;
    margin-bottom: 20px;
}

.ReceiveModal img {
    vertical-align: middle;
    border-style: none;
}

.ReceiveModal__public-address-title {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #fff;
    margin-bottom: 0px;
    text-transform: uppercase;

}

.ReceiveModal__public-address .GhostButton {
    background: none;
    padding: 0;
}

.ReceiveModal__title img {
    margin-left: 15px;
    width: 40px;
    height: unset;
    margin-bottom: 10px;
}

.ReceiveModal__public-address {
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    color: white;
    border: 1px solid #585858;
    display: flex;
    align-items: center;
    padding: 0 0px;
  
}

.ReceiveModal__public-address p {
    width: max-content;
    margin-bottom: 0;
    font-size: 18px;
    color: #FFA4FB;
}
.ReceiveModal .GhostButton{
    margin: 0;
}
.ReceiveModal .ReceiveModal__buttons{
    margin: 0;
  
}
.ReceiveModal .ReceiveModal__public-address{
    border: none;
}
.ReceiveModal__buttons {
    display: inline-block;
    flex-direction: column;
    padding: 7px;
    background: none;
    background: none;
}

.ReceiveModal__qr {
    display: flex;
    align-self: center;
    width: 160px;
    height: 160px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 30px;
}

.ReceiveModal__faucet {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
}

.ReceiveModal__faucet-button {
    width: 110px;
}

.ReceiveModal__faucet-message {
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    color: white;
    margin-top: 12px;
    margin-bottom: 12px;
}
@media (max-width: 991px){
    .ReceiveModal__title img {
        width: 40px;
        height: 70px;
        margin: 0;
    }
}
