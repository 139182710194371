#tokens-page{
    margin-top: 30px;
  }
  #tokens-page .tok-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  #tokens-page .tok-flex h6{
    font-size: 18px;
    font-family: 400;
  }
  #tokens-page .tok-flex img{
    cursor: pointer;
  }
  .token-box-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .dashb .token-box{
    background: #212327;
    border: 1px solid #3A3C40;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    padding: 15px 15px;
    margin-bottom: 15px;
    width: 40%;
  }
  #tokens-page .lefty-flex{
    display: flex;
    justify-content: start;
    align-items: center;
  }
  #tokens-page .lefty-flex img{
    width: 20px;
    margin-right: 10px;
  }
  #tokens-page .lefty-flex h6{
    margin: 0;
    font-size: 16px;
    padding-bottom: 5px;
  }
  #tokens-page .lefty-flex h6 span{
    color: #9F9F9F;
    font-size: 14px;
  }
  #tokens-page .lefty-flex p{
    margin: 0;
    /* color: #9F9F9F; */
    font-size: 14px;
  }
  #tokens-page .lefty-flex p b{
    color: #93FFBE;
  }
  #tokens-page .righty h5{
    margin: 0;
    font-size: 16px;
  }
  #tokens-page .righty h4{
    margin: 0;
    color: #CDCDCD;
    font-size: 16px;
  }
  
  #tokens-page .modal-content{
    background: #282828;
    box-shadow: inset 0px 0px 6px #ffffff45;
    border-radius: 10px;
  }
  #tokens-page .modal-content i{
    color: #fff;
    text-align: end;
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
    cursor: pointer;
  }
  #tokens-page .modal-content .modal-title{
    text-align: center;
  }
  #tokens-page .modal-content .modal-title img{
    width: 50px;
    margin-top: 20px;
  }
  #tokens-page .modal-content .modal-title h5{
    color: #FFF;
    font-size: 20px;
  }
  #tokens-page .form-box {
    padding: 20px;
  }
  #tokens-page .form-box .input-field{
    margin-bottom: 20px;
  }
  #tokens-page .form-box .input-field span{
    display: block;
    color: #C4C4C4;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  #tokens-page .form-box .input-field input{
    background-color: transparent;
    border-radius: 10px;
    border: 1px solid #585858;
    color: #fff;
    width: 90%;
    height: 50px;
    padding: 0 15px;
  }
  #tokens-page .form-box .input-field input::placeholder{
    color: #737373;
  }
  #tokens-page .form-box button{
    color: #fff;
    background: linear-gradient(140deg, #c600ff 0%, #FFA4FB 100%)!important;
    border-radius: 30px;
    border: none;
    outline: none;
    padding: 15px 40px;
    font-size: 16px;
  
  }
  .chart-img{
    background: #212327;
    border-radius: 20px;
    border: 1px solid #3A3C40;
    padding: 20px;
    margin-bottom: 20px;
  }
  .chart-img .canvas{
    height: 500px!important;
  }
.dashb h4{
  text-transform: capitalize;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
}

.chart-btn button{
  border: none;
  outline: none;
  color: #9ca0ac;
  background-color: transparent;
  margin:  0 5px;
}

@media (min-width: 320px) and (max-width: 991px){
  #home-banner .banner-des .btn-des .btn1{
    font-size: 14px;
  }
  #home-banner .banner-des .btn-des .btn2{
    font-size: 14px;
  }
  #home-banner .banner-des h2 {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 24px;
}
.box-img {
  position: relative;
  background: #191919;
  padding: 0;
  border-radius: 10px;
}
.box-flex {
  border-radius: 8px;
  background: #3A3C40;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 9;
  top: 0;
  left: 0;
  margin-bottom: 20px;
}
#features .head h2 {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
}
#statics .des h2 {
  color: #DEDEDE;
  font-size: 22px;
  font-weight: 600;
}
#home-banner::after{
  content: none;
}
#tokens-page .lefty-flex h6
{
  font-size: 14px;
}

}


