.StakesTable {
    color: white;
    border: none;
    border-collapse: collapse;
    width: 100%;
}

.StakesTable thead {
    border-bottom: 1px solid rgba(59, 66, 99, 1);
    background-color: rgba(0, 0, 0, 0.05);

}
.NavBar__wallet-copy-address-icon
{
   
    float: left;
    margin-left: 0;
    margin-top: 0;
        margin-right: 8px;
}
.NavBar__wallet-copy-address-icon img
{
    height: 25px;
}
.StakesTable th {
    text-align: center;
    height: 50px;
    line-height: 44px;
    white-space: nowrap;
    padding: 10px;
    font-size: 14px;
    font-weight: 300;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
}

.csev h5 {
    padding: 1em;
    font-weight: bold;
}

.csev {
    /* max-height: 600px; */
    overflow: auto;
    /* min-height: 370px; */
    /* padding-bottom: 60px; */
    background: #212327;
    border-radius: 20px;
    border: 1px solid #3A3C40;
}

.StakesTable tr {
    text-align: left;

    line-height: 25px;
    border-bottom: 1px solid rgba(59, 66, 99, 1);
}

.StakesTable td {
    text-align: center;
    font-size: 12px;
    padding: 8px;
}

.StakesTable__header--return-height {
    width: 150px;
}

.StakesTable__header--amount {}

.StakesTable__header--holder {
    width: 450px;
}

.fr-time {
    display: flex;
    width: 100%;
    justify-content: center;
}

.fr-time p {
    margin-bottom: 0;
    margin-left: 5px;
}
@media(max-width: 991px){
.csev 
{
    padding-bottom: 0px;
}
.StakesTable th 
{
    line-height: normal;
    white-space: normal;
}
.StakesTable td
{
    line-height: normal;
}
}