.PageHeader {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    height: 70px;
    justify-content: center;
}

.PageHeader__title {
    font-size: 20px;
    line-height: 16px;
    text-transform: uppercase;
    color: white;
    letter-spacing: 1.5px;
    text-transform: capitalize;
}

.PageHeader--is-sticky {
    top: 0;
    position: relative;
    position: sticky;
    justify-content: center;

}

@media (max-width: 991px) {
    .PageHeader__title {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}