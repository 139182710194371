.PrivateKeyModal {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: center;
    justify-content: center;
    background: #ffffff1c 0% 0% no-repeat padding-box;
    padding: 15px 148px;
    box-shadow: inset 0px 0px 6px #ffffff45;
    border-radius: 10px !important;
    opacity: 1;
    -webkit-backdrop-filter: blur(30px);
   
}

.PrivateKeyModal__title {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    color: white;
    margin-bottom: 6px;
}

.PrivateKeyModal__instructions {
    font-size: 18px;
    font-weight: normal;
    text-align: center;
    line-height: 20px;
    color: #B0B0B0;
    margin-bottom: 40px;
}

.custompricvetkeyr {
    width: 50px;
    text-align: center;
    margin: 0 auto !important;
}

.iconxsxa {
    position: absolute;
    z-index: 999;
    right: 2%;
    top: 40%;
}

.private-ky {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 20px;
}

.PrivateKeyModal__private-key {
    text-align: start;
    position: relative;
    background: transparent;
    color: #fff;
    width: 80%;
    font-size: 15px;
    color: #FFA4FB;
    height: 30px!important;
    border: none;
    resize: none;

}

.PrivateKeyModal .GradientButton {
    align-self: center;
    min-width: 200px;
    font-weight: 600;
}
@media only screen and (max-width: 991px) {
    .PrivateKeyModal__title{
        font-size: 18px;
    }
    .PrivateKeyModal__instructions {
        font-size: 14px;
    }
    .Modal__close-button {
        right: 10px !important;
    }
}