.ModalOverlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #000000cf;
    cursor: pointer;
    z-index: 99;
}

.Modal {
    cursor: auto;
}

.Modal:focus {
    outline: none;
}
.ReactModalPortal .Modal__close-button {
    top: 15px;
    right: 20px;
}
.Modal__close-button {
    display: inline-block;
    position: absolute;
    top: 25px;
    right: 30px;
    width: 48px;
    transition: all 0.2s ease-in-out;
    border-radius: 0;
    cursor: pointer;
    background: transparent;
    z-index: 9999;
}

.Modal__close-button img {
    width: 40px;
}

@media (max-width: 991px) {
    .Modal {
        width: 100%;
    }

    .Modal__close-button {
        right: 0 !important;

    }

    .SendModal,
    .ReceiveModal {
        width: 100% !important;
        min-height: auto !important;
    }

    .Moda.sddsdl {
        width: 100% !important;
    }
}