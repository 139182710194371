.TxConfirmationModal {
    display: flex;
    flex-direction: column;
    background: #282828 linear-gradient(180deg, #12A2B8 0%, #000000 100%) 0% 0% no-repeat padding-box;
    width: 600px;
    padding: 20px;
    box-sizing: border-box;
    background: #ffffff1c 0% 0% no-repeat padding-box;
    box-shadow: inset 1px 2px 6px #ffffff45;
    border-radius: 49px;
    opacity: 1;

}

.TxConfirmationModal__title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: white;
    margin-bottom: 20px;
    display: none;
}

.TxConfirmationModal__amount-title {
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    line-height: 24px;
    text-transform: uppercase;
}

.TxConfirmationModal__amount {
    font-size: 41px;
    font-weight: 300;
    color: white;
    line-height: 50px;
}

.TxConfirmationModal__token-name {
    font-size: 14px;
    font-weight: normal;
    color: white;
    text-align: center;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.TxConfirmationModal__to-title {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    line-height: 24px;
    text-transform: uppercase;
    margin-top: 10px;
}

.TxConfirmationModal__to {
    font-size: 16px;
    font-weight: normal;
    color: white;
    text-align: center;
    margin-bottom: 15px;
    line-height: 20px;
    word-break: break-all;
}

.TxConfirmationModal__holder-title {
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    line-height: 24px;
    text-transform: uppercase;
    margin: 10px;
}

.TxConfirmationModal__holder {
    font-size: 16px;
    font-weight: normal;
    color: white;
    text-align: center;
    line-height: 20px;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    margin-bottom: 10px;
    /* max-height: 0px; */
    overflow: auto;
}

.TxConfirmationModal__rows {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #687399;
    margin-bottom: auto;
}

.TxConfirmationModal__row {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    min-height: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #687399;
}

.TxConfirmationModal__row-title {
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    text-align: left;
    line-height: 22px;
    margin-right: 10px;
    text-transform: uppercase;
}

.TxConfirmationModal__row-value {
    font-size: 14px;
    font-weight: normal;
    color: white;
    text-align: left;
    line-height: 22px;
    word-break: break-all;
}

.TxConfirmationModal__password-container {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
    padding-bottom: 30px;
}

.TxConfirmationModal__password-title {
    font-size: 14px;
    font-weight: normal;
    color: white;
    line-height: 16px;
    margin-bottom: 10px;
    text-align: start;
}

.InteractWithContractContent textarea {
    height: 200px !important;
}

.InteractWithContractContent .RoundedInput {
    padding: 15px 15px;
}

.ChoosePasswordCard__password-input::placeholder {
    font-size: 16px;
    color: #737373;
}

@media(max-width: 991px) {
    .TxConfirmationModal {
        width: 100%;
    }

    .TxConfirmationModal__holder {
        font-size: 14px;
        max-height: 40px;
        overflow: auto;
    }

    .TxConfirmationModal__password-container {
        padding-bottom: 10px;
    }

    .thin.con76 {
        font-size: 12px;
    }

    .ModalOverlay {
        top: calc(0px - 60px);
    }

}