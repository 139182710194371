.metatron-note {
    color: red !important;
}
.customds.dropdown
{
    float: none!important;
    bottom: 0;
    border-radius: 4px;
    margin-bottom: 20px;
}
.customds.dropdown .dd-button.dropdown-toggle {
    border-radius: 4px;
    padding: 10px 0px;
    padding-right: 10px;
    text-align: left;
    margin-bottom: 20px;
    font-size: 18px;
    width: 100%;
    background: #282828;
    border-radius: 0;
    border-bottom:1px solid #585858;
    box-shadow: none;
    color: #fff;

}

.customds.dropdown .dd-menu
{
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    padding: 0;
}
.customds.dropdown .dd-menu li{
    padding: 10px;
    font-size: 14px;
}
.customds.dropdown .imgClass
{
    width: 10px;
}

.TxForm .customds.dropdown .dd-menu {
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    padding: 0;
    /* background: transparent; */
    top: 10px!important;
    left: 0!important;
    box-shadow: none;
}