.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

.smallcontainer {
  display: inline-block;
  width: 100%;
  border-radius: 5px;
  padding-left: 10px;
}
.custom-copy span {
  display: block;
}

.custom-copy {
  text-align: end;
  width: 100%;
  padding-right: 20px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.custom-copy p {
  margin: 0;
  padding-right: 10px;
}

.custom-copy a {
  text-decoration: underline;
  cursor: pointer;
  color: #21fcfc !important;
  font-weight: 400;
  display: flex;
}

.custom-copy img {
  margin-left: 10px;
}

.DeployContractContent input {
  height: 50px !important;
}

.DeployContractContent textarea {
  border-radius: 22.291px;
  border: 0.929px solid #424242;
  background: #1D1D1D;
}

.navbar-collapse.dropdown-menu {
  position: relative;
  background: transparent;
  border: none;
}

.parentcontainer {
  width: 100%;
  margin-top: 10px;
}

.smallcontainer TabBarItem {
  width: 23px;
}

.tab-wrapper-send-recieve {
  display: flex;
}

.tab-wrapper-send-recieve a.TabBar {
  width: 158px;
  height: 26px;
}

.mob-dis {
  display: none !important;
  margin-top: 20px;
}

.tab-wrapper-send-recieve a.TabBarItem__icon {
  height: 31px;
}

.sidecontainer {
  margin-top: 10px;
}

.smallcontainer a:first-child {
  background-color: #FC86B7;
}

.smallcontainer a:first-child .TabBarItem__title {
  color: #800036;
  font-weight: 500;
  font-size: 16px;
}

.smallcontainer a:nth-child(2) {
  background-color: #16FFC7;
}

.smallcontainer a:nth-child(2) .TabBarItem__title {
  color: #006C52;
  font-weight: 500;
  font-size: 16px;
}

.explorer-img a {
  background-color: transparent !important;
  width: 100% !important;
}

.smallcontainer a {
  width: 45%;
  border-radius: 5px;
  box-shadow: none;
  border-radius: 15px;
  opacity: 1;

  display: inline-block;
  text-align: center;
  margin: auto;
  height: auto;
  padding: 13px;
}

a.custom-links {
  background-color: transparent !important;
  padding: 0;
  color: #21fcfc;
}

.smallcontainer a:first-child {
  margin-right: 2%;
}

.smallcontainer a div {
  display: inline;
}

.bg-mine {
  color: #ffff;
}

.bg-mine h5 {
  color: #000;
  font-weight: bold;
  margin-bottom: 0;
}

.sd {
  display: block;
  text-align: center;
}

.dropdown-menu {
  right: -25px;
  left: auto;
  width: 22rem;
}

.dropdown-toggle::after {
  display: none;
}

.wrapper-transacton {
  align-items: center;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.wrapper-transacton img {
  cursor: pointer;
  width: 20px;
}
.tokness
{
  font-size: 14px;
}
.righty b{
  font-weight: normal;
}

.tokness span{
  background-color: #21fcfc;
  border-radius: 10px;
  padding: 3px;
  display: inline-block;
  color: #000!important;
  margin-right: 10px;
  font-size: 10px!important;
}
.tokness.ds34d span{
  display: inline;
  padding: 2px;
  font-size: 10px;
  
}
.conteonspa
{
  line-height: normal;

}
.conteonspa a{
  display: block;
  margin-top: 1px;
}
.bg-mine.wrapper-transacton #Refresh {
  right: 25px;
  top: 15px;
}

.bg-mine.wrapper-transacton #Refresh1 {
  right: 25px;
  top: 5px;
}

.wrapper-transacton #Refresh {
  right: 25px;
  top: 5px;
}

.bg-mine h6 {
  font-size: 20px;
  word-break: break-word;
  white-space: break-spaces;
  text-align: start;
}

.thin.con76 {
  text-align: left;
}

.wrapper-transacton #Refresh,
.wrapper-transacton #Refresh1 {
  position: absolute;
  /* right: 0; */
  margin: 0;
  /* top: 0; */
  right: 25px;
  top: 5px;
}

.derivationaoth.BottomBorderInput {
  width: 100%;
  border-radius: 15px;
  background: #000;
  color: #fff;
  padding: 14px;
  font-size: 18px;
  font-weight: 500;
  height: auto;
  text-align: start;
  border: none;
  margin-left: auto;
  display: block;
}

.derivationaothparent {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-top: 0px;
  margin-bottom: 0px;
}

.selectSertivationoath {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 5px;
  width: 100%;
}

.UnlockWalletViaMnemonicPhrase .input-flex {
  display: block;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.derivationaothparent::after {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #5D5B5B;
  position: absolute;
  top: 40%;
  right: 20px;
  content: "";
  z-index: 98;
}

.form-control:focus {
  background-color: #000;
}

.navbar-toggler-icon {
  width: 40px;
  height: 38px;
  background-size: 100%;
  background-image: url("images/menu_bar_icon.png") !important;
}

.tranasctiontype
{
  display: block;
}
.tranasctiontype span{
  display: inline-block;
  padding: 5px;
  font-size: 16px;
  color: #21fcfc;
 border: 1px solid #21fcfc;
  border-radius: 30px;
  width: 100px;
  margin-left: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: center;
}
.tranasctiontype span.active{
  background-image: linear-gradient(140deg, #C600FF 0%, #CFDFFF 50%, #21FCFC 75%)!important;
  color: #000;
}

.spinnerss {
  width: 100%;
  height: 100%;
  position: fixed;
  text-align: center;
  left: 0;
  background: #000000d0;
  top: 0;
  z-index: 9999;
  bottom: 0;
  display: block;
}

.spinnerss img {
  position: relative;
  top: 20%;
}


.imgrefeshinc {
  transition: 0.3s all ease-in-out;
    width: 25px;
    float: right;
    margin-right: 30px;
    margin-top: 10px;
}

.imgrefeshinc:hover {
  transform: scale(1.2) rotate(180deg) !important;
}

.imgrefeshinc1 {
  margin-right: 22px;
  width: 22px;
  margin-top: 7px;
  /* transition: 0.3s all ease-in-out; */
}


.text-danger {
  margin: 10px;
  font-size: 16px;
  font-weight: normal;
  line-height: 21px;
  margin-top: 10px;
  letter-spacing: 1.3px;
  color: #FF4848;
  width: 100%;
  font-weight: 600;
  margin-left: 0;
}

.pending.fin23 {
  filter: hue-rotate(206deg) brightness(95%) contrast(80%)
}

.footercustoma {
  font-size: 12px;
  text-align: center;
  margin-top: 4px;
  padding-left: 10px;
}

.viewonexplorer {
  font-size: 12px;
  color: #d06eff;
  position: absolute;
  bottom: -28px;
  left: 28px;
  z-index: 99;
  cursor: pointer;
}

.dotodd {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

@media only screen and (max-width: 991px) {
  .imgrefeshinc:hover {
    transform: rotate(0deg) !important;
  }
  .pageHeading
  {
    font-size: 18px;
    margin-bottom: 20px;

  }
  #tokens-page .tok-flex
  {
    margin-bottom: 5px;
  }
  .imgrefeshinc
  {
  
    margin-right: 15px;
    margin-top: 0;
  }
  .right-side-details
  {
    font-size: 14px;
    padding: 5px;
    margin-bottom: 10px;
  }
  .tbaleodhe
  {
    max-height: 200px!important;
  }

  .token-box-flex {
    display: block !important;
  }

  .Pages {
    margin: 0px 0px !important;

  }

  .mob-dis {
    display: block;
  }

  .des-des.custom-copy {
    display: none !important;
  }

  .custom-copy span {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px;
  }

  #tokens-page .token-box {
    width: 100% !important;
  }

  .bg-mine.wrapper-transacton #Refresh {
    right: 0px;
    top: 10px;
  }

  .nav-link {
    margin-left: auto;
  }

  .explorer-img a img {
    width: 100%;
  }

  .ver-fac div {
    display: inline-block !important;
  }

  .bg-mine.wrapper-transacton #Refresh1 {
    right: 0px;
    top: 10px;
  }

  .bg-mine h6 {
    font-size: 16px;
  }

  .wrapper-transacton img {
    cursor: pointer;
    width: 15px;
  }

  .selectSertivationoath {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .derivationaothparent {
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .derivationaoth.BottomBorderInput {
    font-size: 16px;
    padding: 8px;
  }

  .ContractPage__detail-view .TabBarItem {
    margin-bottom: 10px !important;
  }

  .wallet-id {
    padding: 20px;
    font-size: 14px;
  }

  .sidecontainer {
    padding: 10px;
  }

  .custom-copy {
    display: block !important;
    padding: 0 !important;
    text-align: center !important;
  }

  .custom-copy a {
    display: block !important;
  }


  .smallcontainer {
    width: 100%;
    padding: 0;
    margin-top: 10px;
  }

  .dropdown-menu {
    right: -40px;
    left: auto;
    background: transparent linear-gradient(180deg, #1d252d 0%, #4d5d6d 100%) 0% 0% no-repeat padding-box;
    width: auto;
    top: 28px;
  }

  .nav-link {
    padding: 0;
  }

  .navbar {
    left: 0;
    right: auto;
    z-index: 99;
  }

  .TabBar1 {
    margin-top: 0 !important;
    height: auto !important;
  }

  .tab-wrapper-send-recieve {
    width: 100%;
  }

  .bg-mine.wrapper-transacton #Refresh {
    right: 0px;
    top: 5px;
  }

  .bg-mine.wrapper-transacton #Refres1 {
    right: 0px;
    top: 5px;
  }

  #tokens-page .righty h5,
  #tokens-page .righty h4 {
    font-size: 14px!important;
  }
  #tokens-page .form-box
  {
    padding: 0!important;
  }
  .FormInputContainer__title
  {
    font-size: 14px!important;
  }
  .sidecontainer,.explorer-img
    {
        display: none;
    }

    .custom-copy p {
     
      white-space: nowrap;
      overflow: hidden;
      color: #21fcfc !important;
      width: 100%;
      padding: 0px 15px;
      font-weight: 600;
      text-overflow: ellipsis;
      position: relative;
  }
  .custom-copy p img
  {
    position: absolute;
    z-index: 99;
    right: 2px;
    top: 1px;
  }
}



