.WithdrawStakeModal {
      display: flex;
    flex-direction: column;
    background: #282828;
    box-shadow: inset 0px 0px 6px #ffffff45;
    opacity: 1;
    -webkit-backdrop-filter: blur(30px);
   
    width: 800px;
    padding: 50px 20px;
    box-sizing: border-box;
}

.WithdrawStakeModal__title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: white;
    margin-bottom: 20px;
}
.WithdrawStakeModal .modal-img {
    width: 30px;
    margin: 0 auto;
}

.WithdrawStakeModal__title img
{
    margin-right: 10px;
}