.GhostButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 15px;
    cursor: pointer;
    padding: 15px 20px;
    box-sizing: border-box;
    background: linear-gradient(140deg, #c600ff 0%, #FFA4FB  100%);
    font-size: 30px;
    font-weight: 600;
    transition: 0.3s all ease-in-out;
    margin-top: 20px;
    font-weight: 600;
}
.GradientButton
{ transition: 0.3s all ease-in-out;

}
.tab-wrapper-send-recieve .TabBarItem 
{
    transition: 0.3s all ease-in-out;
}
.tab-wrapper-send-recieve .TabBarItem:hover
{
    transform: scale(1.05);
}
.GhostButton:hover{
    transform: scale(1.05);
}
.GradientButton:hover{
    transform: scale(1.01);
}
.GhostButton__icon {
    height: 22px;
    margin-right: 8px;
}
.ReceiveModal .GhostButton__icon{
    width: 30px;
    height: unset;
}

.GhostButton__title {
    font-size: 18px;
    font-weight: normal;
    color: white;
    cursor: pointer;
}

.GhostButton__spinner {
    margin-left: auto;
    margin-right: auto;
}

.GhostButton--is-disabled {
    cursor: not-allowed;
    pointer-events: none;
}

@media (max-width: 991px) {
    .GhostButton {
        margin-left: 0 !important;
        margin-top: 10px;
        margin-bottom: 10px;
        justify-content: center;
    }

    .StakesPage__header-buttons {
        /* display: block !important; */
        position: relative !important;
    }
    .StakesPage__header-buttons .GhostButton{ 
        margin: 0 10px;
    }

    .PageHeader {
        height: auto !important;
    }
    .GhostButton__title 
        {
            font-size: 14px;
        }

}

@media (max-width: 991px) {
    .WithdrawStakeModal {
        width: 100%!important;
    }
}