

#tokens-page .tok-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
#tokens-page .tok-flex h6{
    font-size: 18px;
    font-family: 400;
}
#tokens-page .tok-flex img{
    cursor: pointer;
}
#tokens-page .token-box{
    background: #212327;
    border: 1px solid #3A3C40;
    display: block;
  
    border-radius: 15px;
    padding: 15px 15px;
    margin-bottom: 15px;
}
#tokens-page .lefty-flex{
    display: flex;
    justify-content: start;
    align-items: center;
}
#tokens-page .lefty-flex img{
    width: 20px;
    margin-right: 10px;
}
#tokens-page .lefty-flex h6{
    margin: 0;
    /* font-size: 16px; */
    padding-bottom: 5px;
}
#tokens-page .lefty-flex h6 span{
    color: #9F9F9F;
    font-size: 14px;
}
#tokens-page .lefty-flex p{
    margin: 0;
    /* color: #9F9F9F; */
    font-size: 14px;
}
#tokens-page .lefty-flex p b{
    color: #93FFBE;
}

#tokens-page .righty h4{
    margin: 0;
    color: #CDCDCD;
    font-size: 16px;
}
#tokens-page .righty h4 b{
    color: #93FFBE;
}

#tokens-page .modal-content{
    background: #282828;
    box-shadow: inset 0px 0px 6px #ffffff45;
    border-radius: 10px;
}
#tokens-page .modal-content i{
    color: #fff;
    text-align: end;
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
    cursor: pointer;
}
#tokens-page .modal-content .modal-title{
    text-align: center;
}
#tokens-page .modal-content .modal-title img{
    width: 50px;
}
#tokens-page .modal-content .modal-title h5{
    color: #FFF;
    font-size: 20px;
}
#tokens-page .form-box {
    padding: 20px;
}
#tokens-page .form-box .input-field{
    margin-bottom: 20px;
}
#tokens-page .form-box .input-field span{
    display: block;
    color: #C4C4C4;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 600;
}
#tokens-page .form-box .input-field input{
    background-color: transparent;
    border-radius: 10px;
    border: 1px solid #585858;
    color: #fff;
    width: 100%;
    height: 50px;
    padding: 0 15px;
}
#tokens-page .form-box .input-field input::placeholder{
    color: #737373;
}
#tokens-page .form-box button{
    color: #fff;
    background: linear-gradient(140deg, #c600ff 0%, #FFA4FB 100%)!important;
    border-radius: 30px;
    border: none;
    outline: none;
    padding: 15px 40px;
    font-size: 16px;

}