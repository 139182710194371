.OnboardingPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.OnboardingPage__wrapper{
    display: flex;
    flex-direction: column;
    max-width: 560px;
    height: 550px;
}

.OnboardingPage__image{
    height: 360px;
}

.OnboardingPage__title{
    font-size: 14px;
    font-weight: normal;
    line-height: 17px;
    text-align: center;
    color: #92A2D6;
    width: 100%;
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6px;
}

.OnboardingPage__description{
    font-size: 14px;
    font-weight: normal;
    line-height: 17px;
    text-align: center;
    color: white;
    width: 100%;
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
}

.OnboardingPage__footer{
    width: 100%;
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    height: 75px;
}

.OnboardingPage__restore-button{
    display: block;
    width: 100%;
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 18px;
    color: white;
    text-decoration: none;
    text-align: center;
    font-size: 16px;
    font-weight: normal;
}