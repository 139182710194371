.TabBarItem {
    display: block;
    text-align: left;
    padding: 20px 15px;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
}

.logoutcontainer {
    float: right;
    padding: 15px;
}


.TabBarItem--is-active1 .TabBarItem__icon,
.TabBarItem__icon-active {
    display: none;
}

.TabBarItem--is-active1 .TabBarItem__icon-active {
    display: initial;
}

.TabBarItem--is-active1 {
    transition: transform .2s;
    /* transform: scale(1.1); */
    background-image: linear-gradient(140deg, #C600FF 0%, #CFDFFF 50%, #21FCFC 75%)!important;
    border-radius: 30px;
    color: #000;
    padding: 10px 20px;
}
.TabBarItem--is-active1 .TabBarItem__title{
    color: #000;
    font-weight: 600;
}

.TabBarItem__icon,
.TabBarItem__icon-active {
    width: 20px;
    padding-left: 0px;
    padding-right: 0px;

}

.TabBarItem .TabBarItem__icon {
    width: 20px;
}

.sma .TabBarItem__icon-active {
    display: none;
}

.TabBarItem__title {
    font-size: 16px;
    margin-top: 0px;
    color: #9CA0AC;
    display: inline;
    margin-left: 10px;
    word-break: break-word;
    white-space: break-spaces;
}

.PageHeader .TabBarItem--is-active {
    border-radius: 6px;
    padding: 14px;
    text-transform: capitalize;
}


.TabBarItem--is-active .TabBarItem__icon-active {
    display: inline-block;
}

.TabBarItem--is-active .TabBarItem__icon {
    display: none;
    position: relative;
}

.tab-wrapper-send-recieve a .TabBarItem__icon {
    width: auto;
    height: 26px;
    padding-right: 0px;
    display: none;
}
@media (min-width: 1024px) and (max-width: 1600px) { 
    .snd-rec .tab-wrapper-send-recieve a .TabBarItem__icon {
        width: auto;
        height: 20px;
        padding-right: 0px;
    }
    .snd-rec .TabBarItem__title{
        font-size: 14px;
    }
}
@media(max-width: 1100px) {
    .TabBarItem__title {
        font-size: 14px;
    }
}
@media(max-width: 991px) {
    .TabBarItem {
        display: none;
        width: calc(100%/6);
        text-align: center;
    
    }
    .TabBarItem.dropdown-item
    {
        display: inline-block;
    }
    .TabBarItem__title {
        margin-top: 0px;
        display: none;
    }
    /* TabBarItem__title */
}