
#home-banner{
    padding: 40px 0 0;
    position: relative;
}
#home-banner::after{
    content: '';
    position: absolute;
    bottom: 150px;
    /* background-image: url('/img/home/banner1.png'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 60%;
}
#home-banner .banner-des{
    text-align: center;
}
.btn124
{
    background-image: linear-gradient(140deg, #C600FF 0%, #CFDFFF 50%, #21FCFC 75%);
    border-radius: 10px;
    outline: none;
    border: none;
    padding: 10px 15px;
    font-weight: 600;
    color: #000;
    margin-top: 10px;
    display: inline-block;
}
#home-banner .banner-des h2{
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 55px;
}
#home-banner .banner-des p{
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    font-weight: 300;
    max-width: 600px;
    margin: 0 auto;
    padding: 10px 0;
}
#home-banner .banner-des .btn-des{
    padding: 15px 0;
}
#home-banner .banner-des .btn-des .btn1{
    background-image: linear-gradient(140deg, #C600FF 0%, #CFDFFF 50%, #21FCFC 75%);
    border-radius: 10px;
    outline: none;
    border: none;
    padding: 10px 15px;
    font-weight: 600;
    color: #000;
}
#home-banner .banner-des .btn-des .btn2{
    border: 1px solid #C600FF;
    background-color: transparent;
    color: #fff;
    font-weight: 600;
    border-radius: 10px;
    padding: 10px 15px;
    margin-left: 20px;
}
#home-banner .banner-des .banner-img img{
    width: 100%;
    margin-top: 40px;
    z-index: 11;
    position: relative;
}

#features{
    padding: 40px 0;
}
#features .head{
    margin-bottom: 40px;
    text-align: center;
}
#features .head h2{
    color: #fff;
    font-size: 40px;
    font-weight: 600;
}
#features .head p{
    color: rgba(153, 153, 153, 1);
    font-size: 14px;
    font-weight: 400;
    padding: 10px 0;
}
#features .h-100{
    height: 100%;
    padding: 10px 0;
}
#features .box{
    border-radius: 8px;
    box-shadow: 10px 32px 72px 0px rgba(51, 51, 51, 0.03);
    text-align: center;
    padding: 10px 0;
    height: 100%;
}
#features .f1{
    background: linear-gradient(360deg, #525BB0 0%, rgba(82, 91, 176, 0.00) 100%);
}
#features .f2{
    background: linear-gradient(360deg, #AF7FC1 0%, rgba(175, 127, 193, 0.00) 100%);
}
#features .f3{
    background: linear-gradient(360deg, #9C58D0 0%, rgba(156, 88, 208, 0.00) 100%);
}
#features .box img{
    width: 100px;
}
#features .box h5{
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}
#features .box p{
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    max-width: 250px;
    margin: 0 auto;
    padding: 10px 0;
}
#statics{
    padding: 100px 0 50px;
}
#statics .img{
    position: relative;
    border-radius: 22.291px;
    border: 0.697px solid #3A3C40;
    background: #212327;
}
#statics .img img{
    width: 100%;
    position: relative;
}
#statics .des{
    padding-left: 40px;
}
#statics .des h2{
    color: #DEDEDE;
    font-size: 40px;
    font-weight: 600;
}
#statics .des p{
    color: #999;
    font-size: 14px;
    font-weight: 400;
}
#statics .des ul{
    margin: 0;
    padding: 0 0 20px;
}
#statics .des ul li{
    color: #999;
    font-size: 14px;
    padding-bottom: 15px;
    font-weight: 400;
    list-style-position: outside;
    display: flex;
    align-items: flex-start;
}
#statics .des  ul li::before {
    content: '\f111';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    font-size: 6px;
    color: #fff;
    flex: none;
    display: block;
    width: 5px;
    position: relative;
    top: 8px;
    margin-right: 8px;
}
#statics .des .btn1{
    background-image: linear-gradient(140deg, #C600FF 0%, #CFDFFF 50%, #21FCFC 75%);
    border-radius: 10px;
    outline: none;
    border: none;
    padding: 10px 15px;
    font-weight: 600;
    color: #000;
}

#footer{
    background: #121418;
    padding: 40px 0 10px;
}
#footer .logo-txt img{
    width: 100px;
}
#footer .logo-txt p{
    color: #999;
    font-size: 14px;
    padding: 10px 0;
}
#footer .logo-txt ul{
    margin: 0;
    padding: 0;
}
#footer .logo-txt ul li{
    list-style: none;
    display: inline-block;
    padding-right: 25px;
    transition: .3s;
}
#footer .logo-txt ul li:hover{
    transform: scale(1.2);
}
#footer .logo-txt ul li a{
    color: #fff;
}
#footer .logo-txt ul li i{
   font-size: 20px;
}
#footer .logo-txt ul li a img{
    width: 18px;
    margin-top: -5px;
}
#footer .links h5{
    color: #C4C4C4;
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    padding-bottom: 20px;
}
#footer .links ul{
    margin: 0;
    padding: 0;
}
#footer .links ul li{
    list-style: none;
    padding-bottom: 10px;
}
#footer .links ul li a{
    color: #999;
    font-size: 14px;
    font-weight: 300;
}
#footer .links p{
    color: #999;
    font-size: 14px;
    font-weight: 300;
}
#footer .links .input-field{
    position: relative;
}
#footer .links .input-field a{
    color: #c71bff;
}
#footer .links .input-field input{
    width: 100%;
    height: 40px;
    position: relative;
    border-radius: 25px;
    background: #474747;
    color: #fff;
    font-weight: 300;
    border: none;
    outline: none;
    font-size: 14px;
    padding: 0 15px;
}
#footer .links .input-field input::placeholder{
    color: #969696;
    font-weight: 400;
}
#footer .links .input-field img{
    position: absolute;
    right: 10px;
    width: 25px;
    top: 8px;
}
#footer .copyright{
    margin-top: 40px;
}
#footer .copyright p{
    text-align: center;
    color: #9B9B9B;
    font-size: 16px;
    font-weight: 500;
}
#footer .copyright p a{
    text-decoration: underline;
    color: #c71bff;
}
.box-img{
   position: relative;
   background: #191919;
    padding: 100px 60px 40px;
    border-radius: 10px;
    width: 100%;
}
.box-flex{
    border-radius: 8px;
    background: #3A3C40;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    position: absolute;
    z-index: 9;
    top: 25px;
    left: 70px;
}
.box-flex img{
    margin-right: 10px;
    height: 40px;
}
.box-flex h6{
    color: #9CA0AC;
    margin: 0;
}
.box-flex span{
    color: #4AFF92;
}
.box-flex p{
    color: #D4D4D4;
    font-weight: 500;
    margin: 0;
}
.lbank-img {
    position: absolute;
    right: 75px;
    top: 45px;
}
.lbank-img a img{
    width: 40px;
}
@media (min-width: 320px) and (max-width: 991px){
    header .header{
        display: block;
        padding: 0 10px 40px;
    }
    header .header .btns-box {
        display: flex;
        align-items: center;
        justify-content: end;
    }
    .navbar ul li {
        padding: 6px 0;
    }
    header .header .btns-box .custom-top{
        margin-top: 20px;
    }
    header .header .navs > ul > li > a {
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        font-size: 16px;
        color: #fff;
        padding: 25px 5px;
        margin: 0 3px;
        cursor: pointer;
    }
    #home-banner .banner-des h2 {
        color: #fff;
        font-size: 30px;
        font-weight: 700;
        letter-spacing: 2px;
        line-height: 34px;
    }
    #home-banner .banner-des .btn-des {
        padding: 15px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #home-banner .banner-des .btn-des button.btn1{
        padding: 8px 10px;
    }
    #home-banner .banner-des .btn-des button.btn2{
        padding: 8px 10px;
    }
    #home-banner .banner-des .banner-img img {
        width: 80%;
        margin-top: 40px;
        z-index: 11;
        position: relative;
    }
    #features .head h2 {
        color: #fff;
        font-size: 30px;
        font-weight: 600;
    }
    #features {
        padding: 40px 0 0;
    }
    #statics {
        padding: 60px 0 50px;
    }
    #statics .des {
        padding-left: 0;
        padding-top: 20px;
    }
    #statics .des h2 {
        color: #DEDEDE;
        font-size: 26px;
        font-weight: 600;
    }
    .lbank-img {
        display: none;
    }
    .btns-box a.btn1 {
        border: transparent;
        background-color: transparent;
        color: #fff;
        font-weight: 600;
        border-radius: 10px;
        padding: 0;
        margin-right: 0px;
        width: 100%;
        display: block;
        position: relative;
    }
    .btns-box a.btn2 {
        background-image: none;
        border-radius: 10px;
        outline: none;
        border: none;
        padding: 0;
        font-weight: 600;
        color: #fff;
    }
    .btns-box {
        margin-top: 0!important;
    }
    .navbar .navbar-brand img {
        width: 120px!important;
        padding: 10px;
    }
    .wallet-page .unlock-img{
        display: none;
    }
}
