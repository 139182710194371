.ColdWalletSelectorModal{
    display: flex;
    flex-direction: column;
    background-color: #242F4B;
    width: 500px;
    min-height: 540px;
    padding: 20px;
    box-sizing: border-box;
}

.ColdWalletSelectorModal__title{
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: white;
    margin-bottom: 6px;
}

.ColdWalletSelectorModal__rows{
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
}

.ColdWalletSelectorModal__header-address{
    font-size: 16px;
    font-weight: 400;
    color: white;
    text-align: left;
    line-height: 40px;
    text-transform: uppercase;
}

.ColdWalletSelectorModal__header-balance{
    font-size: 16px;
    font-weight: 400;
    color: white;
    text-align: left;
    line-height: 40px;
    text-transform: uppercase;
}

.ColdWalletSelectorModal__row{
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    min-height: 53px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #687399;
    cursor: pointer;
}

.ColdWalletSelectorModal__row:hover{
    background-color: rgba(0,0,0,0.05);
}

.ColdWalletSelectorModal__row-address{
    font-size: 13px;
    font-weight: 600;
    color: #92A2D6;
    text-align: left;
    line-height: 40px;
    flex: 1;
}

.ColdWalletSelectorModal__amount-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 15px;
}

.ColdWalletSelectorModal__amount{
    font-size: 16px;
    font-weight: 100;
    line-height: 20px;
    height: 20px;
    color: white;
    text-align: right;
}

.ColdWalletSelectorModal__amount-icon{
    height: 16px;
    margin-left: 6px;
}

.ColdWalletSelectorModal__footer{
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    min-height: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #687399;
}


.ColdWalletSelectorModal__footer-button{
    font-size: 15px;
    font-weight: 400;
    color: white;
    text-align: left;
    line-height: 30px;
    cursor: pointer;
}

.ColdWalletSelectorModal__footer-button:hover{
    color: rgba(255,255,255,0.8);
}

.ColdWalletSelectorModal__checkmark-icon{
    height: 20px;
    margin-right: 10px;
    margin-left: 10px;
}