.UnlockWalletPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

}
.wallet-page .create-box{
    border-radius: 30px;
    background: linear-gradient(135deg, rgb(144,79,120) 0%, rgb(81,126,138) 100%);
    -webkit-backdrop-filter: blur(129px);
    backdrop-filter: blur(129px);
    box-shadow: inset -1px 0px 12px #ffffff63, 0px 3px 6px #00000029;
    padding: 30px;
}

.unlock-wallet{
    padding: 20px 100px;
    width: 100%;
    height: auto;
    
}

.UnlockWalletPage__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

}

.UnlockWalletPage__title {
    font-size: 26px;
    text-align: start;
    line-height: 28px;
    min-height: 28px;
    color: white;
    margin-bottom: 10px;
    font-weight: 600;
}
.UnlockWalletPage__title p{
    font-weight: 300;
    font-size: 18px;
}
.UnlockWalletPage__title img {
    display: none;
}

.UnlockWalletPage__subtitle {
    font-size: 16px;
    font-weight: normal;
    text-align: start;
    color: white;
    margin: 10px 0 0px;
    width: 100%;
}

.UnlockWalletPage__subtitle span {
    font-size: 18px;
    color: #979797;
}

.UnlockWalletPage__subtitle a {
    margin-left: 6px;
    color: #FF72F9;
}

.UnlockWallet {
    width: 94%;
}

.UnlockWalletCard__content {
    /* display: flex;
    flex-direction: column;
    flex: 1; */
    border-radius: 30px;
    background: linear-gradient(135deg, rgb(144,79,120) 0%, rgb(81,126,138) 100%);
    backdrop-filter: blur(129px);
    box-shadow: inset -1px 0px 12px #ffffff63, 0px 3px 6px #00000029;
    padding: 20px 30px 15px;
    width: 90%;
    margin-left: auto;
}

.UnlockWalletCard__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    border-radius: 5px;
}

.UnlockWalletCard__header ul li:active {
    transition: all 0.1s ease-out;
}

.UnlockWalletCard__footer {
    margin-top: auto;
}

.UnlockWalletCard__title {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    line-height: 26px;
    color: white;
    text-transform: uppercase;
}

.UnlockWalletCard__subtitle {
    font-size: 15px;
    font-weight: normal;
    text-align: center;
    line-height: 20px;
    color: #92a2d6;
}

.UnlockWalletCard__tab-bar {
    width: calc(100% + 34px);
    background-color: transparent;
}

.UnlockWalletCard__warning {
    position: relative;
    font-size: 12px;
    line-height: 21px;
    margin-top: 10px;
    letter-spacing: 1.3px;
    color: #D2D2D2;
    text-align: justify;
    width: 100%;
    font-weight: 600;
    box-shadow: inset -1px 0px 12px #ffffff63, 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 10px 30px;
    background: linear-gradient(135deg, rgb(144 79 120 / 50%) 0%, rgb(81 126 138) 100%);
}
.UnlockWalletCard__warning::before{
    content: '\f05a';
    position: absolute;
    font-family: "Font Awesome 5 Pro";
    margin-left: -20px;
    transition: .3s;
    font-size: 14px;
    color: red;
}
.UnlockWalletViaKeystoreFile {
    /* display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: center;
    justify-content: center;
    background: #ffffff1c 0% 0% no-repeat padding-box;
    border-radius: 7px;
    box-shadow: inset 0px 6px 6px #ffffff45;
    border-radius: 49px;
    opacity: 1;
   
    -webkit-backdrop-filter: blur(30px);
    width: 70%;
    margin: 0 auto;
    padding: 50px 160px; */
}

.UnlockWalletCard__header .unlock-nav {
    width: 70%;
    margin: 0 auto;
}

.unlock-nav li {
    position: relative;
}

.UnlockWalletViaKeystoreFile__footer.mt-5 {
    margin-top: 15px !important;
}

.UnlockWalletViaKeystoreFile__footer {
    margin-top: auto;
    margin-bottom: 0px;
}

.UnlockWalletViaKeystoreFile__file-input {
    outline: none !important;
    padding: 10px 40px;
    font-size:16px;
    height: 50px !important;
    resize: none;
    border-radius: 15px;
    width: 100%;
    overflow: hidden;
    text-align: start;
    letter-spacing: 0px;
    color: #d5d5d5;
    border: 2px dashed #B5B5B5;
    position: relative;
    cursor: pointer;
    margin-bottom: 20px;
}

.UnlockWalletViaKeystoreFile__file-input--has-file::after {
    content: "";
    display: block;
    position: absolute;
    height: 20px;
    width: 20px;
    left: 12px;
    background-image: url("/img/icons/checkmark-green@2x.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 20px;
    top: 12px;
    filter: invert(1);
}


.UnlockWalletViaKeystoreFile__file-input input[type="file"] {
    display: none;
}

.UnlockWalletViaKeystoreFile__title {
    font-size: 20px;
    font-weight: 300;
    line-height: 22px;
    color: white;
    margin-bottom: 10px;
    word-break: break-word;
    white-space: break-spaces;
}

.UnlockWalletViaKeystoreFile__password-input,
.UnlockColdWalletLedger__select {
    outline: none !important;
    border: none !important;
    padding: 20px 12px;
    font-size: 14px;
    height: 50px !important;
    resize: none;
    border-radius: 15px;
    width: 100%;
    overflow: hidden;
    text-align: start;
    letter-spacing: 0px;
    color: #5D5B5B;
    background-color: #000;
}

.UnlockColdWalletLedger__select {
    width: 100%;
    margin-bottom: 18px;
    text-align: center;
}

.UnlockWalletViaMnemonicPhrase {
    /* display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: center;
    justify-content: center;
    background: #ffffff1c 0% 0% no-repeat padding-box;
    padding: 50px 160px;
    box-shadow: inset 0px 6px 6px #FFFFFF45;
    border-radius: 49px;
    opacity: 1;
   
    -webkit-backdrop-filter: blur(30px);
    width: 70%;
    margin: 0 auto; */
}

.UnlockWallets .GradientButton {
   
    margin: auto 0;
    text-align: center;
    background: linear-gradient(140deg, #c600ff 0%, #FFA4FB  100%)!important;
    font-weight: 500;
}

.UnlockWalletViaMnemonicPhrase__footer {
    /* top: 100px;
    margin-bottom: 0px;
    min-width: 300px;
    margin-top: 35px !important; */
}

.UnlockWalletViaMnemonicPhrase__title {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: white;
    margin-bottom: 10px;
}

.PrivateKey {
    margin: 5px;
    font-size: 18px;
}

.UnlockWalletViaMnemonicPhrase__mnemonic {
    outline: none !important;
    border: none !important;
    padding: 6px 12px;
    font-size: 20px;
    resize: none;
    border-radius: 15px;
    width: 100%;
    overflow: hidden;
    text-align: start;
    letter-spacing: 0px;
    color: #fff;
    min-height: 72px!important;
    background-color: #000;
}

.UnlockWalletViaMnemonicPhrase__mnemonic-instructions {
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
    letter-spacing: 1.3px;
    color: #FFFFFF;
    text-align: start;
    width: 100%;

}

.UnlockWalletViaMnemonicPhrase__password-input {
    outline: none !important;
    border: none !important;
    padding: 20px 12px;
    font-size: 18px;
    resize: none;
    border-radius: 15px;
    width: 100%;
    overflow: hidden;
    text-align: start;
    letter-spacing: 0px;
    color: #5D5B5B;
    background-color: #000;
}

.UnlockWalletViaPrivateKey {
    /* display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: center;
    justify-content: center;
    background: #ffffff1c 0% 0% no-repeat padding-box;
    padding: 50px 160px;
    box-shadow: inset 0px 6px 6px #ffffff45;
    border-radius: 49px;
    opacity: 1;
   
    -webkit-backdrop-filter: blur(30px);
    width: 70%;
    margin: 0 auto; */
}

.UnlockWalletViaPrivateKey__footer {
    margin-top: auto;
    position: relative;
    margin-bottom: 0px;
    border-radius: 7px;
    margin-top: 10px !important;
}


.UnlockWallets:hover {
    color: green;
}

.UnlockWalletViaPrivateKey__title {
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    color: white;
    margin-bottom: 15px;
}

.UnlockWalletViaPrivateKey__private-key {
    outline: none !important;
    border: none !important;
    padding: 15px 12px;
    font-size: 18px;
    height: 60px !important;
    resize: none;
    border-radius: 15px;
    width: 100%;
    overflow: hidden;
    text-align: start;
    letter-spacing: 0px;
    color: #5D5B5B;
    background-color: #000;
}
.UnlockWalletViaPrivateKey__private-key::placeholder{
    text-align: start;
}



.UnlockWalletViaPrivateKey__private-key-instructions {
    font-size: 16px;
    font-weight: normal;
    line-height: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
    letter-spacing: 1.3px;
    color: #FFFFFF;
    text-align: start;
    width: 100%;
    font-weight: 500;
}

.UnlockWalletViaPrivateKey__password-input {
    outline: none !important;
    border: none !important;
    padding: 20px 12px;
    font-size: 20px;
    height: 60px !important;
    background-color: #000;
    resize: none;
    border-radius: 15px;
    width: 100%;
    overflow: hidden;
    text-align: start;
    letter-spacing: 0px;
    color: #5D5B5B;
}
.UnlockWalletViaPrivateKey__password-input::placeholder{
    font-size: 14px;
}

.UnlockWalletViaColdWallet {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 360px;
    margin: 20px;
}

.UnlockWalletViaColdWallet__footer {
    margin-top: auto;
}

.UnlockWalletViaColdWallet__title {
    font-size: 19px;
    font-weight: 300;
    line-height: 22px;
    color: white;
    margin-bottom: 0;
}

.UnlockWalletCard__header img {
    height: 50px;
    /* width: 50px; */
}

.landing {
    display: block;
    margin: 50px auto;
}

.unlock-wallet .unlock-img img{
    width: 90%;
    margin: 0 auto;
    display: block;
}

@media only screen and (max-width: 1300px) {
    .UnlockWalletViaPrivateKey {
        padding: 0px;
        width: 100%;
    }
    .UnlockWalletCard__header .unlock-nav {
        width: 100%;
        margin: 0 auto;
    }
    .unlock-wallet {
        padding: 0 10px;
    }
}

@media (min-width: 991px) and (max-width: 1200px) {

    .UnlockWalletViaMnemonicPhrase,
    .UnlockWalletViaKeystoreFile {
        padding: 0px;
        width: 100%;

    }
    .UnlockWalletViaMnemonicPhrase__mnemonic{
        font-size: 14px;
        height: 50px !important;
    }
    .UnlockWalletViaPrivateKey__private-key{
        font-size: 14px;
        height: 50px !important;
    }
}

@media only screen and (max-width : 991px) {
    .UnlockWalletViaKeystoreFile {

        padding: 0px;

    }
    .UnlockWalletViaMnemonicPhrase__mnemonic{
        font-size: 16px;
    }

    .UnlockWalletCard__header img {
        height: 30px;
    }

    .dsda5dwd {
        padding: 0
    }

    .UnlockWalletPage__title {
        margin-top: 0px;
    }

    .UnlockWalletPage__title img {
        display: none;
    }

    .MnemonicWarningCard,
    .MnemonicCard {
        padding: 20px 20px;
    }

    .MnemonicCard__instructions {
        font-size: 18px;
    }

    .MnemonicCard__title {
        font-size: 22px;
    }

    .ReactModal__Content .Modal {
        width: 90%;
        margin: 0 auto;
    }

    .class-compketealwfedws .GradientButton {
        width: 100%;
    }

    .PrivateKeyModal {
        padding: 15px 20px;
    }

    .UnlockWalletViaKeystoreFile__title,
    .UnlockWalletViaPrivateKey__title {
        font-size: 16px;
        white-space: nowrap;
        margin-bottom: 20px;
        word-break: break-word;
        white-space: break-spaces;
    }

    .UnlockWalletViaKeystoreFile__file-input {
        white-space: nowrap;
    }

    .UnlockWalletViaKeystoreFile__password-input,
    .UnlockColdWalletLedger__select {
        /* background-color: white; */
        height: 50px;
        color: white;
        border: none;
        outline: none;
    }


    .UnlockWalletViaPrivateKey {
        padding: 0px
    }

    .UnlockWalletViaMnemonicPhrase {
        padding:0px
    }

    .UnlockWalletPage__subtitle {
        margin-bottom: 20px;
        margin-top: 10px;
    }
    .unlock-wallet .unlock-img img {
        width: 100%;
        margin-bottom: 40px;
    }
    .UnlockWalletCard__content {
        padding: 10px;
        margin-top: 20px;
    }

    .UnlockWalletViaKeystoreFile__footer,
    .UnlockWalletViaMnemonicPhrase__footer,
    .UnlockWalletViaPrivateKey__footer {
        margin-bottom: 0;
        width: 100%;
    }

    .UnlockWalletViaMnemonicPhrase__footer .GradientButton,
    .UnlockWallets .GradientButton,
    .UnlockWalletViaPrivateKey__footer .GradientButton {
      
        background: linear-gradient(140deg, #c600ff 0%, #FFA4FB  100%)!important;
    }

    .ChoosePasswordCard__footer .GradientButton {
        width: 100%;
    }

}

@media only screen and (max-width: 991px) {
    .UnlockWalletViaKeystoreFile {
        width: 100%;
    }
   
    .cusomtmobile-row
    {
        margin: 0;
    }
    .unlock-nav li{
        word-break: break-word;
        white-space: break-spaces;
        width: 30%
    }
    .UnlockWalletPage__title {
        font-size: 20px;
        padding: 10px 10px 0;
    }
    .UnlockWalletPage__subtitle{
        text-align: center;
        padding-bottom: 10px;
    }
    .derivationaoth.BottomBorderInput{
        width: 90%;
    }

    .PrivateKeyModal {
        padding: 15px 10px!important;
    }

    .PrivateKeyModal__private-key {
        padding: 15px 30px!important;
        font-size: 14px!important;
    }
   
    .GradientButton--is-disabled {
        font-size: 16px;
    }
    .UnlockWalletViaMnemonicPhrase , .UnlockWalletViaPrivateKey{
        width: 100%;
    }
    .UnlockWalletPage__title {
        margin-top: 0px;
    }
    .UnlockWalletPage__subtitle span,
    .UnlockWalletPage__subtitle a {
        font-size: 12px;
    }

    .GradientButton {
        padding: 15px 15px;
        font-size: 16px;
    }

    .UnlockWalletViaMnemonicPhrase__mnemonic,
    .UnlockWalletViaMnemonicPhrase__password-input,
    .UnlockWalletViaKeystoreFile__file-input,
    .UnlockWalletViaKeystoreFile__password-input,
    .UnlockWalletViaPrivateKey__private-key,
    .UnlockWalletViaPrivateKey__password-input,
    .ChoosePasswordCard__password-input {
        padding: 15px 12px;
        font-size: 14px;
        height: 50px !important;
        width: 100%;
        border-radius: 33px;
    }
    .UnlockWalletViaMnemonicPhrase__mnemonic{
        height: 45px!important;
        font-size: 12px;
    }
    .UnlockWalletViaKeystoreFile__file-input {
        padding: 12px 40px;
        word-break: break-word;
        white-space: break-spaces;
        height: auto!important;
    }

    .UnlockWalletViaMnemonicPhrase__title {
        font-size: 14px;
        margin-bottom: 0px;
    }

    .UnlockWalletViaMnemonicPhrase__mnemonic-instructions,
    .UnlockWalletCard__warning,
    .UnlockWalletViaPrivateKey__private-key-instructions {
        font-size: 14px;
        line-height: 20px;
    }

    .UnlockWalletCard__header .unlock-nav {
        width: 100%;
        padding: 0px!important;
    }

    .dd-button {
        font-size: 16px;
    }

    .mnemonic,
    .keystore,
    .PrivateKey,
    .MnemonicWarningCard__warning-body,
    .MnemonicCard__view-private-key,
    .MnemonicCard__phrase-container p {
        font-size: 12px;
    }

    .UnlockWalletViaKeystoreFile__file-input--has-file::after {
        top: 12px;
    }

    .MnemonicCard__instructions {
        font-size: 16px;
        line-height: 25px;
    }

    .ChoosePasswordCard__title {
        font-size: 20px;
    }

    label.lab {
        font-size: 16px;
    }
    .ChoosePasswordCard__message span,.text-danger
    {
        font-size: 10px;
    }

    .MnemonicWarningCard,
    .MnemonicCard {
        padding: 20px 0px;
    }

    .MnemonicWarningCard__subtitle,
    .MnemonicWarningCard__warning-title {
        font-size: 18px;
    }

    .MnemonicWarningCard__warning-body,
    .MnemonicWarningCard__footer .GradientButton {
        width: 100%;
    }

    .MnemonicWarningCard__title,
    .MnemonicCard__title {
        font-size: 22px;
    }

    .CreateWalletPage__title {
        font-size: 24px;
    }

    .MnemonicCard__content {
        padding-bottom: 0px;
    }

    .MnemonicCard__phrase-container {
        width: 100%;
        margin-bottom: 15px;
        padding: 0 14px;
        font-size: 10px;
        line-height: 0;
        height: 200px;
    }

    .MnemonicCard__footer .GradientButton {
        width: 100%;
        margin: 0 auto;
    }

    .PrivateKeyModal {
        padding: 15px 15px;
    }

    .PrivateKeyModal__instructions {
        font-size: 16px;
        margin-bottom: 15px !important;
    }

    .WalletCreationCompleteCard {
        padding: 15px 10px;
    }

    .class-compketealwfedws .GradientButton {
        margin-top: 30px;
        width: 100%;
    }

    .class-compketealwfedws p {
        font-size: 18px;
    }
    .unlock-wallet {
        padding: 0;
    }
}