.StakePurposeSelector {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.StakePurposeSelectorItem {
    background-color: #333;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-radius: 10px;
    padding: 20px;
    text-align: start;
}

.StakePurposeSelectorItem__title {
    font-size: 17px;
    font-weight: 600;
    line-height: 21px;
    color: #8C8C8C;
    text-decoration: none;
    cursor: inherit;
}

.StakePurposeSelectorItem__subtitle {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: #666;
    text-decoration: none;
    cursor: inherit;
    white-space: normal;
}

.StakePurposeSelectorItem--is-selected {
    border: 1px solid #893dfc;
    background-color: rgba(0, 0, 0, 0.1);
}

.StakePurposeSelectorItem--is-selected .StakePurposeSelectorItem__title {
    color: #ffffff;
}

.StakePurposeSelectorItem--is-selected .StakePurposeSelectorItem__subtitle {
    color: white;
}

.StakePurposeSelectorItem--is-disabled {
    cursor: not-allowed;
    opacity: 0.3;
}
@media(max-width: 767px)
{
    .StakePurposeSelectorItem__title {
        font-size: 14px;
    }

}