.wallet-page{
    /* padding: 0 100px; */
}

.wallet-page{
    padding: 20px 100px;
    width: 100%;
    height: auto;
    
  
}

/* .wallet-page .unlock-img img{
    width: 90%;
    margin: 0 auto;
    display: block;
} */
.wallet-page  .unlock-img {
    background: linear-gradient(0deg, #310057 0%, rgba(1, 1, 1, 0.00) 100%);
    height: 615px;
    border-radius: 0px 0px 40px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.CreateWalletPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.CreateWalletPage__wrapper {
    border-radius: 30px;
    background: linear-gradient(135deg, rgb(144,79,120) 0%, rgb(81,126,138) 100%);
    -webkit-backdrop-filter: blur(129px);
    backdrop-filter: blur(129px);
    box-shadow: inset -1px 0px 0px #ffffff63, 0px 3px 6px #00000029;
    padding: 20px 30px 15px;
    width: 90%;
    margin-left: auto;
}

.CreateWalletPage__title {
    font-size: 26px;
    text-align: start;
    line-height: 28px;
    min-height: 28px;
    color: white;
    margin-bottom: 10px;
    font-weight: 600;
}

.CreateWalletPage__title img {
    display: none;
}

.CreateWalletPage__subtitle {
    font-size: 16px;
    font-weight: normal;
    text-align: start;
    color: #C4C4C4;
    margin-bottom: 0px;
    margin-top: 15px;
}

input#agreedToTerms {
    width: auto;
    flex: 0 0 16px;
    height: 25px!important;
}

.CreateWalletPage__subtitle a {
    margin-left: 6px;
    color: #FF72F9;
}

.ChoosePasswordCard {
    
}

label.lab {
    letter-spacing: 0px;
    color: #FFFFFF;
    font-size: 18px;
    padding-bottom: 5px;
    font-weight: 400;
}

.ChoosePasswordCard__content {
    margin-top: 20px;
}

.ChoosePasswordCard__header {
    text-align: center;
    margin-bottom: 20px;
}

.ChoosePasswordCard__footer {
    margin-top: auto;
    margin-top: 20px;
}

a.GradientButton:hover {
    color: #ffffff;
}

.ChoosePasswordCard__footer .GradientButton {
    /* width: 50%; */
    padding: 20px;
    margin: 0;
    border-radius: 15px!important;
    background: linear-gradient(140deg, #c600ff 0%, #FFA4FB  100%)!important;
    font-weight: 500;
}

.ChoosePasswordCard__title {
    font-size: 22px;
    font-weight: 500;
    text-align: start;
    line-height: 26px;
    color: #ffffff;
    letter-spacing: 1px;
    padding-bottom: 5px;
}

.ChoosePasswordCard__subtitle {
    font-size: 18px;
    font-weight: normal;
    text-align: start;
    line-height: 20px;
    color: #C4C4C4;
}

.ChoosePasswordCard__inputs {
    display: flex;
    flex-direction: column;
}

.ChoosePasswordCard__password-input {
    outline: none !important;
    border: none !important;
    padding: 20px 12px;
    font-size: 20px;
    height: 60px !important;
    resize: none;
    border-radius: 15px;
    width: 100%;
    overflow: hidden;
    text-align: start;
    letter-spacing: 0px;
    color: #5D5B5B;
    margin-bottom: 0px;
    background-color: #000;

}

.ChoosePasswordCard__password-input:last-of-type {
    margin-bottom: 2px;
}

.ChoosePasswordCard__error {
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    line-height: 20px;
    color: #ff1d25;
    min-height: 20px;
}

::placeholder {
    color: #92a2d6;
    opacity: 1;
}

:-ms-input-placeholder {
    color: #92a2d6;
}

::-ms-input-placeholder {
    color: #92a2d6;
}

.ChoosePasswordCard__message-wrapper {
    display: flex;
    flex: 1;
    margin-top: 0px;
    margin-bottom: 0px;
    align-items: flex-start;
}

.ChoosePasswordCard__message {
    font-size: 13px;
    font-weight: normal;
    text-align: left;
    line-height: 16px;
    color: white;
    margin-left: 8px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
}

.ChoosePasswordCard__message span {
    font-size: 14px;
    line-height: 22px;
    margin-top: 10px;
    margin-bottom: 20px;
    letter-spacing: 1.3px;
    color: #FFFFFF;
    text-align: center;
    width: 100%;
    font-weight: 400;
}

.MnemonicWarningCard {
    
}
.MnemonicWarningCard .mt-4{
    display: none;
}
.ChoosePasswordCard .none{
    display: none;
}

.MnemonicWarningCard__content {
    margin-top: 20px;
}

.keystore {
    margin: 2px;
    font-size: 18px;

}

.keystore :active {
    background: red;
    transition: all 0.1s ease-out;
    border: 9px solid #fafafa;
    box-shadow: 0 7px 30px #cc0f0fa6;
    color: #cc0f0f4d;
}




.MnemonicWarningCard__header {
    margin-bottom: 20px;
}

.MnemonicWarningCard__footer {
    margin-top: auto;
    margin-top: 30px;
}

.MnemonicWarningCard__footer .GradientButton {
    width: 35%;
    margin: 0;
    font-weight: 600;
    border-radius: 15px!important;
    background: linear-gradient(140deg, #c600ff 0%, #FFA4FB  100%)!important;
}

.MnemonicWarningCard__title {
    font-size: 22px;
    font-weight: 500;
    text-align: start;
    line-height: 26px;
    color: #ffffff;
    letter-spacing: 1px;
    padding-bottom: 5px;
}

.MnemonicWarningCard__subtitle {
    font-size: 18px;
    font-weight: normal;
    text-align: start;
    line-height: 20px;
    color: #C4C4C4;
}

.MnemonicWarningCard__warning-title {
    font-size: 22px;
    font-weight: 600;
    text-align: start;
    line-height: 20px;
    color: #ff1d25;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 10px;
}

.MnemonicWarningCard__warning-body {
    font-size: 18px;
    font-weight: 100;
    text-align: start;
    line-height: 20px;
    margin-bottom: 15px;
    color: #C4C4C4;
    width: 100%;
}

.MnemonicWarningCard__warning {
    display: block;
    flex-direction: column;
    flex: 1;
    align-items: center;
}

.MnemonicWarningCard__icon {
    height: 100%;
    width: 60%;
    align-self: center;
}

.MnemonicCard {
    margin-top: 20px;
}

.MnemonicCard__content {

}
.MnemonicCard__content .none{
    display: none;
}

.MnemonicCard__header {
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.MnemonicCard__footer {
    margin-top: 20px;
}

.MnemonicCard__footer .GradientButton {
    width: 35%;
    font-weight: 600;
    font-size: 20px;
    margin: 0;
    border-radius: 15px!important;
    background: linear-gradient(140deg, #c600ff 0%, #FFA4FB  100%)!important;
}

.MnemonicCard__title {
    font-size: 22px;
    font-weight: 500;
    text-align: start;
    line-height: 26px;
    color: #ffffff;
    letter-spacing: 1px;
    padding-bottom: 5px;
}

.MnemonicCard__subtitle {
    font-size: 18px;
    font-weight: normal;
    text-align: start;
    line-height: 20px;
    color: #C4C4C4;
}

.MnemonicCard__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.MnemonicCard__instructions {
    font-size: 18px;
    font-weight: normal;
    text-align: start;
    line-height: 20px;
    color: #C4C4C4;
    margin-bottom: 20px;
}

.MnemonicCard__phrase-container {
    text-align: center;
    position: relative;
    padding: 20px 70px;
    width: 100%;
    margin-bottom: 0px;
    border-radius: 10px;
    border: 1px solid #6E6E6E;
    background: linear-gradient(128deg, #5B3E55 0%, rgba(44, 43, 52, 0.39) 100%);
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MnemonicCard__phrase-container p {
    line-height: 25px;
    font-size: 20px;
    word-spacing: 6px;
    font-weight: 500;
    color: #fff;
    margin: 0;
    user-select: text;
    text-align: start;
}
.MnemonicCard .wallet-copy img{
    height: unset;
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 40px;
    cursor: pointer;
}

.MnemonicCard__view-private-key {
    font-size: 18px;
    font-weight: normal;
    text-decoration: none;
    margin-top: 0;
    cursor: pointer;
    margin-bottom: 0px;
    color: #DFDFDF;
    text-align: end;
    width: 100%;
    text-decoration: underline;
}
a.MnemonicCard__view-private-key:hover {
    color: #CECDCE;
}

.WalletCreationCompleteCard {
margin-bottom: 50px;
}

.imgcss {
    width: 30%;
    margin: 0;
    display: none;
}

.class-compketealwfedws h3{
    font-size: 22px;
    font-weight: 500;
    text-align: start;
    line-height: 26px;
    color: #ffffff;
    letter-spacing: 1px;
    /* width: 70%; */
}


.class-compketealwfedws p {
    font-size: 18px;
    font-weight: normal;
    text-align: start;
    line-height: 20px;
    color: #fff;
    /* width: 70%; */
}

.class-compketealwfedws .GradientButton {
    width: 45%;
    padding: 16px;
    font-weight: 400;
    margin: 0;
    border-radius: 15px!important;
    background: linear-gradient(140deg, #c600ff 0%, #FFA4FB  100%)!important;
}

.WalletCreationCompleteCard__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 17px;
    padding-top: 0;
    margin-left: auto;
    width: 100%;
    max-width: 580px;
    margin-right: auto;
}

.WalletCreationCompleteCard__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80px;
    margin-bottom: 17px;
}

.mnemonic {
    margin: 4px;
    font-size: 18px;
}

.Private Key {}

.WalletCreationCompleteCard__footer {
    margin-top: auto;
}

.WalletCreationCompleteCard__title {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    line-height: 26px;
    color: white;
    text-transform: uppercase;
}

.WalletCreationCompleteCard__subtitle {
    font-size: 15px;
    font-weight: normal;
    text-align: center;
    line-height: 20px;
    color: #92a2d6;
}

.WalletCreationCompleteCard__success-title {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    line-height: 20px;
    color: #1bded0;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 10px;
}

.WalletCreationCompleteCard__success-body {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    line-height: 20px;
    color: #92a2d6;
}

.WalletCreationCompleteCard__success {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
}

.WalletCreationCompleteCard__icon {
    height: 116px;
    width: auto;
    align-self: center;
}

@media(max-width: 991px) {
    .class-compketealwfedws {
        margin-top: 0;
    }
    .CreateWalletPage__wrapper{
        padding: 15px;
    }
    .CreateWalletPage__title {
        margin-top: 0px;
    }

    .ChoosePasswordCard {
        padding: 0
    }
    .wallet-page .unlock-img img {
        width: 100%;
        margin-bottom: 30px;
    }
}
@media(max-width: 1300px){
    .CreateWalletPage__wrapper{
        padding: 15px;
    }
    .wallet-page {
        padding: 0 10px;
    }
}
@media(max-width: 991px) {
    .CreateWalletPage__title {
        margin-top: 0px;
    }
    .class-compketealwfedws h3{
        font-size: 22px;
    }
    .wallet-page {
        padding: 0 0px;
    }
    .CreateWalletPage__wrapper{
        padding: 15px;
    }
    .wallet-page .unlock-img img {
        width: 100%;
        margin-bottom: 20px;
    }
}