.GradientButton {
    display: block;
    padding: 15px 45px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    background: linear-gradient(140deg, #c600ff 0%, #FFA4FB  100%)!important;
    color: #fff;
    transition: all 0.2s ease-in-out;
    box-shadow: inset 0px 6px 6px #00000029, 0px 3px 6px #00000029;
    border-radius: 15px;
    opacity: 1;
    backdrop-filter: blur(11px);
    -webkit-backdrop-filter: blur(11px);
    font-size: 18px;
    transition: all 0.2s ease-in-out;
    /* width: 50%; */
    margin: 0 auto;
    font-weight: 600;
}
.DeployContractContent  .GradientButton{
    margin: 20px 0 0!important;
} 

.ExportKeystoreContent a.GradientButton {
    background: #D06EFF 0% 0% no-repeat padding-box;
    width: fit-content;
    margin: 0;
}

.InteractWithContractContent a.GradientButton {
    margin-top: 8px;
    text-align: start;
    margin: 20px 0;
    width: 30%;
    text-align: center;
    font-weight: 500;
}

.DeployContractContent a.GradientButton {
    margin-top: 19px;
    background-image: linear-gradient(140deg, rgba(198, 0, 255, 1) 0%, rgba(255, 164, 251, 1) 75%);
    width: 30%;
    border-radius: 15px;
    padding: 12px 24px;
    font-weight: 500;
}

.GradientButton:hover {
    box-shadow: 0 0 4px rgb(137 61 252);

}

.newGradientButton {
    display: block;
    border-radius: 4px;
    padding: 15px 45px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    margin: auto;
    background: transparent linear-gradient(90deg, #00FFFF 0%, #4ED0FF 27%, #E370FF 84%, #E26CFF 100%) 0% 0% no-repeat padding-box;
    color: #fff;
    transition: all 0.2s ease-in-out;
}

.GradientButton:active {
    box-shadow: 0 0 25px rgba(33, 237, 186, 0.25);
}

.GradientButton:active {
    box-shadow: 0 0 25px rgba(33, 237, 186, 0.25);
}

.GradientButton--is-disabled {
    cursor: not-allowed;
    pointer-events: none;
    background: #ffffff38 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 6px 6px #00000029, 0px 3px 6px #00000029;
    border-radius: 16px;
    opacity: 0.5;
    backdrop-filter: blur(11px);
    -webkit-backdrop-filter: blur(11px);
    font-size: 18px;
    font-weight: 600;
}

.UnlockWalletViaPrivateKey__footer .GradientButton,
.UnlockWalletViaMnemonicPhrase__footer .GradientButton {
    padding: 15px;
    padding: 16px;
    margin: 0;
    font-weight: 600;
    background: linear-gradient(140deg, #c600ff 0%, #FFA4FB  100%)!important;
}