.SettingsPage {
    display: flex;
    flex-direction: row;
    flex: 1;
    color: white;
    background: #ffffff1c 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 6px 6px #ffffff45;
    border-radius: 49px;
    opacity: 1;
    -webkit-backdrop-filter: blur(30px);
   
}

.SettingsPage__detail-view {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 32px;
    padding-right: 32px;
    overflow-y: auto;
    position: relative;
    border-radius: 20px;
}

.PageHeader.PageHeader__title {
    text-align: center;
}

.SettingsSection {
    display: flex;
    flex-direction: column;
    /* margin-top: 20px; */
    padding-bottom: 50px;
}

.SettingsSection__content{
    padding: 20px 0px 0;
}
.ExportKeystoreContent input::placeholder{
    color: #fff;
}

.transaction-table {
    color: white;
    overflow: auto;
    max-height: 400px;
    overflow-x: auto;
    overflow-y: auto;
    white-space: nowrap;
    background: #212327;
    border-radius: 20px;
    border: 1px solid #3A3C40;
}

.SettingsSection__title {
    font-size: 24px;
    font-weight: 100;
    margin-bottom: 15px;
    text-align: center;
    text-transform: uppercase;
}

.InputTitle {
    font-size: 16px;
    font-weight: normal;
    color: #fff;
    margin-bottom: 10px;
    padding-left: 10px;
}
.positin-relative{
    position: relative;
    margin-bottom: 20px;
}

*:focus {
    outline: 0;
}
input:-webkit-autofill {
    -webkit-box-shadow: none;
    -webkit-text-fill-color: #000 !important;
}
button:focus, input:focus, a:focus {
    text-decoration: none !important;
    outline: none !important;
}

.ExportKeystoreContent{
    position: relative;
}
.ExportKeystoreContent i{
    position: absolute;
    right: 24px;
    top: 52px;
    color: #fff;
    cursor: pointer;
}

@media (max-width: 991px) {
    .SettingsPage__detail-view {
        padding-left: 15px;
        padding-right: 15px;
    }
}